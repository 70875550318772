import type { WritableComputedRef } from 'vue';

import { useApiGetAdminUsers } from '~/apiClient';
import { FE_PAGE_PARAM } from '~/constants/pagination';
import type { FilterOptions } from '~/types/filters.type';

export const ADMIN_USER_FILTER_QUERY_KEY = 'admin-user';

type UseAdminUserFilterReturn = {
    adminUserFilter: WritableComputedRef<string | undefined>;

    adminUserOptions: FilterOptions<string>;
};

export const useAdminUserFilter = (): UseAdminUserFilterReturn => {
    const route = useRoute();

    const { data: adminUsers } = useApiGetAdminUsers();

    const adminUserFilter = computed({
        get: () => route.query[ADMIN_USER_FILTER_QUERY_KEY] as string | undefined,
        set: value => {
            navigateTo({
                query: { ...route.query, [ADMIN_USER_FILTER_QUERY_KEY]: value, [FE_PAGE_PARAM]: undefined },
            });
        },
    });

    const adminUserOptions = computed(() =>
        (adminUsers.value ?? []).map(adminUser => ({ value: adminUser.id, label: adminUser.email })).sort((a, b) => a.label.localeCompare(b.label))
    );

    watch(adminUserOptions, val => {
        if (!val.find(o => o.value === adminUserFilter.value)) adminUserFilter.value = undefined;
    });

    return { adminUserFilter, adminUserOptions };
};
